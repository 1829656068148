import { TableRow } from '@mui/material'
import type { TableRowProps } from '@mui/material/TableRow/TableRow'
import { colorTokens } from '@orus.eu/pharaoh'
import type { ReactElement, MouseEvent as ReactMouseEvent } from 'react'

type TableRowClickableProps = TableRowProps & {
  onClick: () => void
}

export function TableRowClickable(props: TableRowClickableProps): ReactElement {
  const handleClick = (event: ReactMouseEvent<HTMLElement>) => {
    if (isClickOnElementRelevantAsClickOnTr(event.target as HTMLElement)) {
      props.onClick()
    }
  }

  const passedProps: TableRowProps & { href?: string } = {
    ...props,
    href: undefined,
    sx: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: colorTokens['color-bg-base-hover'],
      },
      ...props.sx,
    },
  }

  return <TableRow {...passedProps} onClick={handleClick} />
}

/**
 * This helper function figures out if it makes sense considering that the click on a descendent is a click
 * on the table line. If it's a click on an interactive element inside the line, we should ignore the click.
 *
 * @param element the clicked element.
 */
function isClickOnElementRelevantAsClickOnTr(element: HTMLElement | null): boolean {
  for (let checkedElement = element; checkedElement !== null; checkedElement = checkedElement.parentElement) {
    const tagName = checkedElement.tagName.toLowerCase()
    if (tagName === 'tr') {
      return true
    }
    if (tagName === 'a' || tagName === 'button' || tagName === 'input') {
      return false
    }
  }
  return false
}
